// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translation: {
    aboutUs: 'Sobre nós',
    account: 'Conta',
    accountRecovery: 'Recuperar conta',
    accountReset: 'Resetar conta',
    actions: 'Acões',
    addGroupStat: 'Adicionar estatística',
    addPlayerStat: 'Adicionar estatística de atleta',
    addTeamStat: 'Adicionar estatística de time',
    addMember: 'Adicionar membro',
    addMatch: 'Adicionar confronto',
    advanced: 'Avancadas',
    average: 'Média',
    awayTeam: 'Equipe visitante',
    awayScore: 'Pontuacao visitante',
    back: 'Voltar',
    by: 'por',
    cancel: 'Cancelar',
    date: '{{date, date}}',
    dateTime: '{{date, datetime}}',
    dateTimeText: 'Data | Hora',
    doubleClickToConfirm: 'Dois cliques para confirmar',
    draws: 'Chaveamentos',
    editDraw: 'Editar chaveamento',
    editElimination: 'Editar eliminacoes',
    editFixedPlayerStatsTable: 'Editar tabelas de estatísticas',
    editGame: 'Editar jogo',
    editOrganization: 'Editar organizacão',
    editPhase: 'Editar fase',
    editPlayer: 'Editar atleta',
    editTeam: 'Editar equipe',
    editTournament: 'Editar torneio',
    eliminationMenuTitle: 'Menu eliminacões',
    eliminations: 'Eliminacoes',
    email: 'Email',
    filter: 'Filtro',
    firstTeam: 'Primeira equipe',
    firstTeamScore: 'Pontuacao primeira equipe',
    fixed: 'Manual',
    fixedPlayerStatsTables: 'Tabelas de Estatísticas',
    forgotYourPassword: 'Esqueceu sua senha?',
    here: 'aqui',
    homeTeam: 'Equipe casa',
    homeScore: 'Pontuacao casa',
    informations: 'Informacões',
    isGameFinished: 'Jogo encerrado',
    isInProgress: 'Em progresso',
    games: 'Partidas',
    general: 'Gerais',
    groupStats: 'Estatísticas de grupo',
    language: 'Linguagem',
    location: 'Local',
    match: 'Confronto',
    matches: 'Confrontos',
    manage: 'Gerenciar',
    members: 'Membros',
    message: 'Mensagem',
    myAccount: 'Minha conta',
    name: 'Nome',
    new: 'Novo',
    newDraw: 'Novo chaveamento',
    newElimination: 'Nova eliminacao',
    newFixedPlayerStatsTable: 'Nova tabela de estatiticas de atletas',
    newGame: 'Nova partida',
    newOrganization: 'Nova organizacão',
    newPhase: 'Nova fase',
    newPlayer: 'Nova(o) atleta',
    newTeam: 'Nova equipe',
    newTournament: 'Novo torneio',
    noGamesScheduled: 'Sem jogos agendados',
    organizations: 'Organizacões',
    orSignUp: 'Ou crie uma conta',
    password: 'Senha',
    phases: 'Fases',
    pin: 'Fixar',
    player: 'Atleta',
    players: 'Atletas',
    playerStats: 'Estatísticas de atletas',
    placeholder: 'Temporário',
    repeatPassword: 'Senha novamente',
    rounds: 'Chaveamentos',
    roundsMenuTitle: 'Menu chaveamentos',
    rows: 'Linhas',
    save: 'Salvar',
    saveOrder: 'Salvar ordem',
    scanCodeToAccessIt:
      'Acesse jogos, classificação, estatísticas e muito mais sobre o torneio.',
    search: 'Pesquisar',
    searchTournaments: 'Busca de torneios',
    secondTeam: 'Segunda equipe',
    secondTeamScore: 'Pontuacao segunda equipe',
    send: 'Enviar',
    sendYourFeedback: 'Mande seu feedback',
    sendYourFeedbackFailMsg: 'Problema ao enviar, tente mais tarde!',
    sendYourFeedbackSuccessMsg: 'Mensagem enviada com sucesso!',
    signIn: 'Entrar',
    signInWithFacebook: 'Entre com Facebook',
    signUp: 'Novo usuário',
    signUpWithFacebook: 'Acesse com Facebook',
    signOut: 'Sair',
    socialNetworks: 'Redes sociais',
    sort: 'Ordenar',
    source: 'Fonte',
    sport: 'Esporte',
    sportsPackages: {
      basketball_5x5: {
        title: 'Use pacote de basquete 5x5',
        description: 'Pacote com estatísticas de basquete 5x5'
      }
    },
    startTyping: 'Digite para pesquisar',
    statistics: 'Estatísticas',
    sum: 'Soma',
    summary: 'Resumidas',
    username: 'Usuário',
    useAsAnApp: 'Use no celular',
    useAsAnAppLine1: 'Abra o menu do seu navegador de internet.',
    useAsAnAppLine2: 'Clique em "Adicionar na Área de Trabalho".',
    useAsAnAppLine3: 'Confirme opção clicando em "Adicionar".',
    unpin: 'Desfixar',
    team: 'Equipe',
    teams: 'Equipes',
    teamStats: 'Estatísticas de equipes',
    theBestAppToManageTournaments:
      'A web app mais maneira para gerenciar torneios',
    theSourceCodeIsLicensed: 'Esse código-fonte é licenciado',
    theWebsiteContentIsLicensed: 'O conteúdo do website é licenciado',
    thisTournamentIsUnderContruction: 'Este torneiro está em construção.',
    title: 'Título',
    tournaments: 'Torneios',
    tournamentNotFound: 'Torneio não encontrado',
    type: 'Tipo',
    with: 'com',
    whatIsGoChamps: 'O que é o Go Champs?',
    whatIsGoChampsLine1:
      'Go Champs! É uma aplicacão para você criar seu torneio e gerenciar fases, times, jogos, classificacões e muito mais.',
    whatIsGoChampsLine2:
      'As informacões ficam disponibilizadas para qualquer pessoa acessar e pode acompanhar seu torneio.',
    whatIsGoChampsLine3: 'Acesse nosso torneio exemplo clicando',
    youNeedCreateTeams: 'Você precisa criar times.',
    youNeedCreatePhases: 'Você precisa criar fases.',
    value: 'Valor'
  }
};
